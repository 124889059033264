<template>
  <div class="view pa24">
    <el-button
      class="mr10"
      type="primary"
      v-if="jurisdictionA || userInfo.roleId || userInfo.main"
      @click="
        centerDialogVisible = true;
        bdetermine = true;
        editUpdate = false;
      "
      >添加视频</el-button
    >
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column align="center" type="index" label="序号" />
        <el-table-column
          prop="userName"
          align="center"
          label="上传者"
          width="100"
          show-overflow-tooltip
        />
        <el-table-column
          prop="uploadTime"
          align="center"
          label="上传时间 "
          show-overflow-tooltip
        />
        <el-table-column
          prop="playVolume"
          width="80"
          align="center"
          label="播放量pv"
        />
        <el-table-column
          prop="guestsNum"
          width="80"
          align="center"
          label="获客人数"
        >
          <template slot-scope="scope">
            <span style="color: #fd634e">{{ scope.row.guestsNum }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="likesNum"
          width="50"
          align="center"
          label="点赞"
        />
        <el-table-column
          prop="forwardNum"
          width="80"
          align="center"
          label="转发次数"
        />
        <el-table-column
          prop="forwardNum"
          width="80"
          align="center"
          label="关注人数"
        />
        <el-table-column
          prop="title"
          align="center"
          label="标题"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          prop="videoGoods"
          label="推荐商品"
          width="150"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.videoGoods">
              <span v-for="(item, index) in scope.row.videoGoods" :key="index"
                >{{ item.name }}、</span
              >
            </div>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          align="center"
          label="地址"
          show-overflow-tooltip
        />
        <el-table-column align="center" prop="coverImg" label="封面">
          <template slot-scope="scope">
            <el-image
              style="width: 60px; height: 60px"
              :src="scope.row.coverImg"
              fit="contain"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" width="100" prop="state" label="状态">
          <template slot-scope="scope">
            {{ scope.row.state == 0 ? "已下架" : "已上架" }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="
                edit(scope.row, false);
                bEdit = true;
              "
              >详情</el-button
            >
            <el-button
              type="text"
              @click="
                edit(scope.row, true);
                bEdit = false;
                editUpdate = true;
              "
              >编辑</el-button
            >
            <!-- <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @onConfirm="del(scope.row.videoId)"
              icon="el-icon-info"
              icon-color="red"
              title="确定要删除吗？"
            >
              <el-button
                slot="reference"
                class="ml10"
                style="color: #fd634e"
                type="text"
                >删除</el-button
              >
            </el-popconfirm> -->
            <customPopconfirm
              confirm-button-text="确定"
              k
              cancel-button-text="取消"
              @confirm="del(scope.row.videoId)"
              icon="el-icon-info"
              icon-color="red"
              title="确定要删除吗？"
            >
              <el-button
                slot="reference"
                class="ml10"
                style="color: #fd634e"
                type="text"
                >删除</el-button
              >
            </customPopconfirm>
            <customPopconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @confirm="updateData(scope.row)"
              icon="el-icon-info"
              icon-color="red"
              :title="
                scope.row.state == 0
                  ? '确定要上架这个视频吗？'
                  : '确定要下架这个视频吗？'
              "
            >
              <el-button slot="reference" class="ml10" type="text" size="small">
                {{ scope.row.state == 0 ? "上架" : "下架" }}
              </el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
      title="添加视频"
      :visible.sync="centerDialogVisible"
      @open="openDialog"
      @close="close"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      width="50%"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="78px"
      >
        <el-form-item label="视频名称" prop="title">
          <el-input v-model="ruleForm.title"></el-input>
        </el-form-item>
        <el-form-item label="添加位置" prop="address">
          <el-input id="suggestId" v-model="ruleForm.address"></el-input>
          <div id="baidumap"></div>
        </el-form-item>
        <template v-if="bEdit">
          <el-form-item label="上传时间">
            <el-input disabled v-model="ruleForm.uploadTime"></el-input>
          </el-form-item>
          <el-form-item label="播放量">
            <el-input disabled v-model="ruleForm.playVolume"></el-input>
          </el-form-item>
          <el-form-item label="获客人数">
            <el-input disabled v-model="ruleForm.guestsNum"></el-input>
          </el-form-item>
          <el-form-item label="点赞">
            <el-input disabled v-model="ruleForm.likesNum"></el-input>
          </el-form-item>
          <el-form-item label="转发">
            <el-input disabled v-model="ruleForm.forwardNum"></el-input>
          </el-form-item>
        </template>
        <el-form-item label="视频内容" prop="url">
          <!-- <el-upload
            class="avatar-uploader"
            :action="$store.state.uploadingUrl"
            :show-file-list="false"
            accept=".mp4"
            :on-progress="handProgress"
            :on-success="handleAvatarSuccessVideo"
            :before-upload="beforeAvatarUploadVideo"
          >
            <video
              v-if="ruleForm.url"
              :src="ruleForm.url"
              width="100%"
              height="100%"
              controls
            >
              <source src="movie.mp4" type="video/mp4" />
            </video>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              只能上传MP4文件，且不超过500M
            </div>
          </el-upload> -->

          <el-upload
            :action="$store.state.uploadingUrl"
            class="avatar-uploader"
            :show-file-list="false"
            accept=".mp4"
            :on-change="handleChange"
            :auto-upload="false"
          >
            <video
              v-if="ruleForm.url"
              :src="ruleForm.url"
              width="100%"
              height="100%"
              controls
            >
              <source src="movie.mp4" type="video/mp4" />
            </video>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              只能上传MP4文件，且不超过500M
            </div>
            <div class="mosk" v-if="uploadLoading">
              <el-progress
                :text-inside="true"
                :stroke-width="24"
                :percentage="uploadRate"
                status="success"
              ></el-progress>
              <div>正在{{uploadRate==100?'合并':'上传'}}视频...</div>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="视频封面" prop="coverImg">
          <el-upload
            :class="{
              'avatar-uploader': true,
              'avatar-coverImg': true,
              disabled: uploadDisabled,
            }"
            :action="$store.state.uploadingUrl"
            list-type="picture-card"
            :on-progress="handProgress"
            :on-success="handleAvatarSuccessCoverImg"
            :on-remove="handleRemove"
            :file-list="fileList"
            :limit="1"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              上传图片的最佳尺寸：750像素*750像素，其他尺寸会影响页面效果，格式png，jpeg，jpg。大小不超过2M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="推荐商品" prop="suggestions">
          <el-input
            v-model="ruleForm.suggestions"
            maxlength="15"
            placeholder="请输入商品推荐语"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="" v-if="!bEdit">
          <el-button
            type="primary"
            style="margin-left: 0px; margin-bottom: 30px"
            @click="dialogDetailVisible = true"
            >商品选择</el-button
          >
        </el-form-item>
        <el-form-item label="" v-if="!bEdit">
          <div
            class="d-flex w220 justify-content-between align-items-center select-goods-list"
            v-for="(item, index) in ruleForm.videoGoods"
            :key="index"
          >
            <div>{{ item.name }}</div>
            <div>￥{{ item.price }}</div>
            <i class="el-icon-delete" @click="goodsDelete(index)"></i>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine" v-if="bdetermine"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="选择商品"
      :visible.sync="dialogDetailVisible"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      width="50%"
      center
    >
      <commonTable
        :tableData="goodsList"
        :loading="goodsListLoading"
        @handleSizeChange="goodsListSizeChange"
        @handleCurrentChange="goodsListCurrentChange"
        @handleSelectionChange="handleSelectionChange"
        :currentPage="goodsListCurrentPage"
        :total="goodsListTotal"
      >
        <template v-slot:table>
          <el-table-column align="center" type="selection"></el-table-column>
          <el-table-column
            prop="goodsId"
            align="center"
            label="产品id"
            show-overflow-tooltip
          />
          <el-table-column prop="goodsName" align="center" label="产品 " />
          <el-table-column prop="goodsTypeName" align="center" label="分类" />
          <el-table-column prop="price" align="center" label="价格" />
          <el-table-column prop="stockNum" align="center" label="库存" />
          <el-table-column prop="dealNum" align="center" label="总销量" />
          <el-table-column prop="createTime" align="center" label="发布时间">
            <template slot-scope="scope">
              {{ scope.row.createTime | getDataTimeSec }}
            </template>
          </el-table-column>
        </template>
      </commonTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDetailVisible = false">取 消</el-button>
        <el-button @click="queding" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { mapState } from "vuex";
import customPopconfirm from "@/components/common/customPopconfirm";
import {
  queryPage,
  add,
  deleteById,
  updateData,
  update,
  getGoodsInfoPC,
  uploadCheck,
  uploadFile,
  uploadMerge,
} from "@/api/videoManage";
import { getDataTimeSec } from "@/utils";
import SparkMD5 from "spark-md5";
let ac;
export default {
  data() {
    return {
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      goodsList: [],
      goodsListCurrentPage: 1, //当前页
      goodsListPageSize: 10, //显示条数
      goodsListLoading: false, //表格加载
      goodsListTotal: 0, //总条数
      centerDialogVisible: false,
      dialogDetailVisible: false,
      bEdit: false,
      bdetermine: true,
      editUpdate: true,
      isUpdate: true,
      fileList: [],
      userInfo: this.$store.state.loginRoot.userInfo,
      ruleForm: {
        title: "",
        address: "",
        url: "",
        coverImg: "",
        suggestions: "",
        videoGoods: [],
        videoId: "",
        type: 3,
      },
      rules: {
        title: [{ required: true, message: "请输入视频名称", trigger: "blur" }],
        address: [
          { required: true, message: "请输入添加位置", trigger: "blur" },
        ],
        url: [{ required: true, message: "请上传视频内容" }],
        coverImg: [{ required: true, message: "请上传视频封面" }],
      },
      jurisdictionA: "",
      jurisdictionB: "",
      isLoadUp: false,
      //上传状态
      uploadLoading: false,
      //上传视频进度
      uploadRate: 0,
    };
  },
  components: {
    commonTable,
    customPopconfirm,
  },
  filters: {
    getDataTimeSec(timeStamp) {
      var dateTime = new Date(timeStamp);
      var no1new = dateTime.valueOf();
      var year = dateTime.getFullYear();
      var month = dateTime.getMonth() + 1;
      var day = dateTime.getDate();
      var hour = dateTime.getHours();
      var minute = dateTime.getMinutes();
      var second = dateTime.getSeconds();
      var now = new Date();
      var now_new = now.valueOf();
      var milliseconds = 0;
      var timeSpanStr;
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minute < 10) {
        minute = "0" + minute;
      }

      milliseconds = now_new - no1new;

      if (milliseconds <= 1000 * 60 * 1) {
        timeSpanStr = "刚刚";
      } else if (
        1000 * 60 * 1 < milliseconds &&
        milliseconds <= 1000 * 60 * 60
      ) {
        timeSpanStr = Math.round(milliseconds / (1000 * 60)) + "分钟前";
      } else if (
        1000 * 60 * 60 * 1 < milliseconds &&
        milliseconds <= 1000 * 60 * 60 * 24
      ) {
        timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60)) + "小时前";
      } else if (
        1000 * 60 * 60 * 24 < milliseconds &&
        milliseconds <= 1000 * 60 * 60 * 24 * 15
      ) {
        timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60 * 24)) + "天前";
      } else if (
        milliseconds > 1000 * 60 * 60 * 24 * 15 &&
        year == now.getFullYear()
      ) {
        timeSpanStr = year + "-" + month + "-" + day;
      } else {
        timeSpanStr = year + "-" + month + "-" + day;
      }
      return timeSpanStr;
    },
  },
  computed: {
    ...mapState(["routerArr"]),
    uploadDisabled() {
      return this.ruleForm.coverImg !== "";
    },
  },
  watch: {
    routerArr(val) {
      console.log(val);
      this.jurisdictionA = val.includes("080");
      this.jurisdictionB = val.includes("081");
      if (this.jurisdictionA || this.jurisdictionB) {
        this.queryPage();
      }
    },
  },
  created() {
    this.getGoodsInfoPC();
  },
  mounted() {
    this.queryPage();
  },
  beforeDestroy() {
    // ac.removeEventListener("onConfirm", this.setValue);
  },
  methods: {
    async queryPage() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        queryType: "web",
      };
      if (this.jurisdictionA) {
        data.queryType = "web";
        if (this.jurisdictionB) {
          data.queryType = "";
        }
      } else if (this.jurisdictioB) {
        data.queryType = "";
      }

      try {
        this.loading = true;
        const result = await queryPage(data);

        this.loading = false;
        const { total, list } = result.data.pageInfo;
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.ruleForm.coverImg = "";
    },
    /**@method 加载商品列表 */
    async getGoodsInfoPC() {
      let data = {
        pageSize: this.goodsListPageSize,
        pageNum: this.goodsListCurrentPage,
      };

      try {
        this.goodsListLoading = true;
        const result = await getGoodsInfoPC(data);
        this.goodsListLoading = false;
        const { total, list } = JSON.parse(result?.data?.pageInfo);
        this.goodsList = list;
        this.goodsListTotal = total;
      } catch (error) {
        this.goodsListLoading = false;
      }
      // const reslut
    },
    /**@method 模态框打开时的回调 */
    openDialog() {
      let map = new BMap.Map("baidumap");
      ac = new BMap.Autocomplete({
        //建立一个自动完成的对象
        input: "suggestId",
        location: map,
      });
      ac.addEventListener("onConfirm", this.setValue);
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        title: "",
        address: "",
        url: "",
        coverImg: "",
        suggestions: "",
        videoGoods: [],
        videoId: "",
        type: 3,
      }),
        (this.fileList = []);
      this.bEdit = false;
    },
    setValue(e) {
      const {
        item: { value },
      } = e;
      this.ruleForm.address = `${value.city}${value.district}${value.business}`;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    goodsListSizeChange(val) {
      this.goodsListPageSize = val;
      this.getGoodsInfoPC();
    },
    goodsListCurrentChange(val) {
      this.goodsListCurrentPage = val;
      this.getGoodsInfoPC();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    goodsDelete(index) {
      this.ruleForm.videoGoods.splice(index, 1);
    },
    queding() {
      this.ruleForm.videoGoods = [];
      this.multipleSelection.forEach((item) => {
        let data = {
          companyId: item.companyId,
          createdTime: item.createTime,
          goodsId: item.goodsId,
          videoGoodId: item.goodsId,
          videoId: this.ruleForm.videoId,
          name: item.goodsName,
          price: (item.price / 100).toFixed(2),
        };
        this.ruleForm.videoGoods.push(data);
        this.dialogDetailVisible = false;
      });
    },
    /**@method 上传时回调 */
    handProgress(event, file, fileList) {
      if (this.isUpdate) {
        this.$message.closeAll();
        this.openFullScreen();
        this.$message({
          message: "正在上传",
        });
      }
      this.isUpdate = false;
    },
    /**@method 上传成功 */
    // handleAvatarSuccessVideo(res, file) {
    //   this.$message.closeAll();
    //   this.openFullScreen(1);
    //   this.$message({
    //     message: "上传成功",
    //     type: "success",
    //   });
    //   this.isUpdate = true;
    //   this.ruleForm.url = `https://ma-oos.oss-cn-beijing.aliyuncs.com/${res.data}`;
    // },
    // beforeAvatarUploadVideo(file) {
    //   console.log("file", file);
    //   const isLt2M = file.size / 1024 / 1024 < 500;
    //   if (!isLt2M) {
    //     this.$message.error("上传视频大小不能超过 500MB!");
    //   }
    //   return isLt2M;
    // },
    async handleChange(e) {
      if (e) {
        if (e.size >= 1024 * 1024 * 500) {
          return this.$message.error("上传视频大小不能超过 500MB!");
        }
        try {
          let file = e.raw;
          //获取文件md5
          let md5 = await this.calculateMD5(file);
          //检测文件是否上传
          let checkRes = await uploadCheck({ identifier: md5 });
          console.log("checkRes---", checkRes);
          if (checkRes.data.uploaded) {
            //已上传 直接赋值
            this.ruleForm.url = `https://ma-oos.oss-cn-beijing.aliyuncs.com/${checkRes.data.url}`;
            return;
          }
          this.uploadRate = 0;
          this.uploadLoading = true;
          //分片上传
          let chunkSize = 2 * 1024 * 1024;
          let totalChunks = Math.ceil(e.size / chunkSize);
          for (let index = 0; index < totalChunks; index++) {
            let params = {
              file: file.slice(index * chunkSize, (index + 1) * chunkSize),
              chunkNumber: index + 1,
              chunkSize,
              currentChunkSize:
                index + 1 < totalChunks ? chunkSize : e.size % chunkSize,
              totalChunks,
              totalSize: e.size,
              identifier: md5,
              filename: e.name,
            };
            let uploadRes = await uploadFile(params);
            console.log("uploadRes----", uploadRes);
            if (uploadRes.code != 200) {
              this.uploadLoading = false;
              return this.$message.error(uploadRes.message);
            } else {
              //计算上传百分比
              let num =
                index + 1 == totalChunks
                  ? 1
                  : ((index + 1) * chunkSize) /  e.size;
              this.uploadRate = Math.ceil(num * 100);
              console.log(this.uploadRate,num);
            }
            //最后一片 合并
            if (index + 1 == totalChunks) {
              let mergeRes = await uploadMerge({...params,file:''});
              console.log("mergeRes----", mergeRes);
              if (mergeRes.code == 200) {
                this.uploadLoading = false;
                this.$message.success("视频上传成功！");
                this.ruleForm.url = `https://ma-oos.oss-cn-beijing.aliyuncs.com/${mergeRes.data}`;
              } else {
                this.uploadLoading = false;
                return this.$message.error(mergeRes.message);
              }
            } else {
              continue;
            }
          }
        } catch (err) {
          this.uploadLoading = false;
          this.$message.error("上传视频失败!");
        }
      }
    },
    //获取文件md5
    async calculateMD5(file) {
      return new Promise((resolve, reject) => {
        const chunkSize = 2097152; // 2MB
        const spark = new SparkMD5.ArrayBuffer();
        const fileReader = new FileReader();
        let cursor = 0;

        fileReader.onerror = function () {
          reject("Error reading file");
        };

        fileReader.onload = function (e) {
          spark.append(e.target.result); // Append array buffer
          cursor += e.target.result.byteLength;
          if (cursor < file.size) {
            loadNext();
          } else {
            resolve(spark.end());
          }
        };

        function loadNext() {
          const fileSlice = file.slice(cursor, cursor + chunkSize);
          fileReader.readAsArrayBuffer(fileSlice);
        }

        loadNext();
      });
    },
    handleAvatarSuccessCoverImg(res, file) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.openFullScreen(1);
      this.ruleForm.coverImg = `https://ma-oos.oss-cn-beijing.aliyuncs.com/${res.data}`;
    },
    // 整页加载
    openFullScreen(a) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (a == 1) {
        loading.close();
      }
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val, b) {
      let data = JSON.parse(JSON.stringify(val));
      this.centerDialogVisible = true;
      this.ruleForm = data;
      console.log(this.ruleForm);
      this.ruleForm.type = b ? 1 : 2;
      this.ruleForm.coverImg = data.coverImg;
      this.fileList = [{ url: data.coverImg, uid: 1 }];
      this.bdetermine = b;
      this.bEdit = b;
    },
    /**@method 添加视频 */
    determine() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            if (this.ruleForm.suggestions == "") {
              delete this.ruleForm.suggestions;
            }
            let data = {
              ...this.ruleForm,
              link: "",
            };
            this.$message({
              message: "正在保存",
            });
            if (this.editUpdate) {
              await update(data);
            } else {
              await add(data);
            }
            this.centerDialogVisible = false;
            this.$message.closeAll();
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await deleteById({ videoId: val });
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    /**@method 上架视频 */
    async updateData(val) {
      let data = {
        videoId: val.videoId,
        state: val.state ? 0 : 1,
      };
      await updateData(data);
      await this.queryPage();
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 180px;
  text-align: center;
}
/deep/ .avatar-coverImg .el-upload--text {
  width: 100px;
  height: 100px;
}
/deep/ .avatar-coverImg .avatar-uploader-icon {
  line-height: 100px;
}
.select-goods-list {
  margin-bottom: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  transition: all 0.4s;
}
.select-goods-list:hover {
  background: #f5f5f6;
  transition: all 0.4s;
  cursor: pointer;
}
/deep/ .disabled .el-upload--picture-card {
  display: none;
}

.avatar-uploader {
  position: relative;
  .mosk {
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba($color: #333, $alpha: 0.5);
    width: 360px;
    height: 180px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .el-progress {
      width: 200px;
      margin: 40px 0 10px;
    }
  }
}
</style>
